













































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Comment } from '@/store/comments';

@Component
export default class CommentComponent extends Vue {
  @Prop() comment!: Comment;
  @Prop() depth!: number;

  private replyComment(reply: Comment){
    this.$emit('clickReply', reply);
  }

}
