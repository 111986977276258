












import { Component, Prop, Vue } from 'vue-property-decorator';
import { CommentModule, Comment } from '@/store/comments';
import CommentComponent from '@/components/CommentComponent.vue';

@Component({
  components: {
    CommentComponent
  },
  name: 'comments'
})

export default class Comments extends Vue {
  @Prop() comments!: Comment[];
  @Prop({default: 0}) depth!: number;

  private relayReply(reply: Comment){
    //console.log("from comment");
    //console.log(reply.id);
    this.$emit("showReplyForm", reply);
  }
}
