





















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class InputWrap extends Vue {
  @Prop() private label!: string;
  @Prop() private hint!: string;
}
